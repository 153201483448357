



































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const form = ref<any>(null);

    const model = reactive({
      name: "",
      status: true,
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return "Endpointu nie znaleziono";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        isActive: model.status,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/publication`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: `${root.$tc(
              "event.panel.abstractModule.publication.add.success"
            )}`,
          });

          state.success = true;
          if (action === "new") {
            model.name = "";
            model.status = true;
          } else {
            root.$router.back();
          }
          form.value?.resetValidation();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    return {
      form,
      model,
      state,
      onSubmit,
      rules,
    };
  },
});
